/* ! Log in page */
.signInForm {
  transition: 0.2s ease;
  display: grid;
  margin: auto;
  padding: 1rem 0 1rem 0;
  /* height: 50vh; */
  align-items: center;
  text-align: center;
  grid-template-columns: auto-fill;
  max-width: 15rem;
  gap: 1rem;
  justify-content: center;

  & input {
    transition: 0.2s ease;
    color: var(--grey0);
    background-color: black;
    margin: 1rem 0 3rem 0;
    padding: 5%;
    width: 90%;
    height: 2.5rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;

    &:focus {
      transition: 0.2s ease;
      border: none;
      outline: none;
    }
  }
  & button {
    transition: 0.2s ease;
    background-color: var(--grey0);
    margin: 1rem;
    width: 100%;
    height: 2.5rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    align-self: center;
    justify-self: center;

    &:hover {
      transition: 0.2s ease;
      background-color: white;
    }
    &:active {
      transition: 0.2s ease;
      box-shadow: inset 3px 2px 3px black;
    }
  }
  & label {
    color: var(--grey0);
    margin-top: 1rem;
    width: 100%;
    height: 2.5rem;
    font-size: 1.9rem;
  }
}

.LoadingContainer {
  display: flex;
  margin: auto;
  align-items: center;
  height: 60vh;
  width: 100%;
  justify-content: center;
}
