/* * ! /////////////// about ///////////////  * */

#about {
  display: grid;
  grid-template-columns: 10vw 80vw 10vw;
  /* grid-template-rows: 100vh; */
  grid-auto-rows: min-content;
  grid-column-gap: 0;
  grid-row-gap: 0;
  scroll-behavior: smooth;
  -webkit-filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308);
  filter: drop-shadow(0.3125rem 0.3125rem 0.5rem #051308ec);
}

@media only screen and (max-device-width: 600px) {
  #about {
    display: grid;
    // grid-template-columns: 2vw 1fr 2vw;
    grid-auto-rows: min-content;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.about-img {
  border-radius: 0 3.125rem 0;
  height: 20rem;
  margin: 0 0 4rem 0;

  position: relative;
  top: 4.5vh;
  // left: -5vw;
  padding-right: 2rem;

  float: left;
  z-index: 9;

  -webkit-filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
  filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
}

.about-content {
  padding: 0vh 3vw;
  text-align: center;
}

.about-content h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 4rem;

  //   line-height: 0.9;
  text-align: left;

  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

.about-content h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;

  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

.about-content p {
  color: #fff;
  font-family: "Poppins", sans-serif;

  line-height: 1.2rem;
  padding-top: 0.5rem;
  text-align: left;
  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

@media screen and (max-width: 1000px) {
  .about-img {
    height: 20rem;
  }
  .about-content h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 950px) {
  .about-img {
    height: 9rem;
    float: none;

    margin: 2rem 0 1rem 0;

    position: static;
  }

  .about-content h1 {
    // font-weight: 500;
    text-align: center;
  }

  .about-content p {
    text-align: center;
  }
}
