.aboutContainer {
  padding-top: 0rem;
  margin: 0;
}

.card {
  height: 14rem;
  width: 17rem;
  display: flex;
  flex-direction: column;
  border-radius: 0 3.125rem 0;
  margin: 1rem;
}

.header {
  height: 2.2rem;
  background: #051308;
  color: white;
  border: solid 0.5rem #051308;
  border-radius: 0 3rem 0 0;

  margin: 0.15rem;
  width: 15.6rem;

  box-shadow: 0 0 0.5rem #051308;
}

.card-container {
  background-color: #051308b2;
  height: 14rem;
  width: 15rem;
  padding: 1rem;
  border-radius: 0 0 0 3.125rem;
  box-shadow: 0 0 0.5rem #051308;
}

.header h3 {
  font-size: 1.2rem;
  text-align: center;
}

.card-container p {
  font-weight: 400;
  font-size: 1.1rem;

  letter-spacing: 0.05rem;
  line-height: 1.2rem;

  padding: 0 2rem;
}

#team {
  min-height: fit-content;
  max-height: fit-content;
  margin-bottom: -10rem;
}

.team-content {
  min-height: 35rem;
  padding: 10vh 0 0 0;
  // margin-top: -3rem;
  // width: 100%;
  // text-align: center;
  position: relative;
}

.team-content h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 4rem;

  line-height: 0.9;
  text-align: left;

  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

/* * ! /////////////// individual colors ///////////////  * */

.Aarde {
  border: solid 0.125rem #97d700;
  position: absolute;
  top: 11rem;
  left: 5%;
}

.Aarde h3 {
  color: #97d700;
}

.Toit {
  border: solid 0.125rem #2dccd3;
  position: absolute;
  top: 21rem;
  left: 26%;
}

.Toit h3 {
  color: #2dccd3;
}

.Goede {
  border: solid 0.125rem #ffa400;
  position: absolute;
  top: 11rem;
  right: 26%;
}

.Goede h3 {
  color: #ffa400;
}

.McIntyre {
  border: solid 0.125rem #c5299b;
  position: absolute;
  top: 21rem;
  right: 5%;
}

.McIntyre h3 {
  color: #c5299b;
}

@media screen and (max-width: 1080px) {
  .Aarde {
    position: absolute;
    top: 12rem;
    left: 2%;
  }

  .Toit {
    position: absolute;
    top: 24rem;
    left: 15%;
  }

  .Goede {
    position: absolute;
    top: 12rem;
    right: 15%;
  }

  .McIntyre {
    position: absolute;
    top: 24rem;
    right: 2%;
  }

  .team-content h1 {
    font-weight: 500;
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  #team {
    min-height: 75rem;
    max-height: 75rem;
    // margin-bottom: -10rem;
  }

  .team-content {
    height: 70rem;
  }

  .Aarde {
    position: absolute;
    top: 12rem;
    left: 2%;
  }

  .Toit {
    position: absolute;
    top: 42rem;
    left: 2%;
  }

  .Goede {
    position: absolute;
    top: 27rem;
    right: 2%;
  }

  .McIntyre {
    position: absolute;
    top: 57rem;
    right: 2%;
  }

  .team-content h1 {
    font-weight: 500;

    text-align: center;
  }
}

@media screen and (max-width: 810px) {
  .team-content {
    height: 70rem;
  }

  .Aarde {
    position: absolute;
    top: 12rem;
    left: 2%;
  }

  .Toit {
    position: absolute;
    top: 42rem;
    left: 2%;
  }

  .Goede {
    position: absolute;
    top: 27rem;
    right: 2%;
  }

  .McIntyre {
    position: absolute;
    top: 57rem;
    right: 2%;
  }

  .team-content h1 {
    font-weight: 500;

    text-align: center;
  }
}

@media screen and (max-width: 750px) {
  .team-content h1 {
    font-size: 3rem;
  }
}
