/* * ! /////////////// news ///////////////  * */

html,
body {
  height: auto;
}

#news {
  display: grid;
  grid-template-columns: 10vw 80vw 10vw;
  grid-auto-rows: 67vh;
  margin-bottom: -5rem;
  padding: 7rem 0 0 0;
  grid-column-gap: 0;
  grid-row-gap: 0;

  position: relative;
  scroll-behavior: smooth;
}

.news-content {
  color: #fff;

  margin: 8vh 1% 0 1%;
}

.news-logo {
  max-height: 15rem;
  display: block;
  margin: 0 auto 2rem auto;
}

img.news-logo {
  animation: wobble alternate 10s infinite;
  animation-fill-mode: both;
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-10%) rotate(-5deg);
  }
  30% {
    transform: translateX(20%) rotate(5deg);
  }
  45% {
    transform: translateX(-15%) rotate(-5deg);
  }
  60% {
    transform: translateX(10%) rotate(5deg);
  }
  75% {
    transform: translateX(-5%) rotate(-5deg);
  }
  100% {
    transform: translateX(0%);
  }
}

.news-content h1 {
  font-size: 2.5rem;
  font-weight: 500;

  font-family: "Poppins", sans-serif;

  text-align: center;
  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

.news-content p {
  color: #ffa400;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.7rem 0;
  text-align: center;
  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

@media screen and (max-width: 900px) {
  #news {
    grid-auto-rows: 70vh;
  }
  .news-logo {
    max-height: 7rem;
    margin-top: 15vh;
  }

  .news-content h1 {
    font-size: 1.7rem;
    font-weight: 500;
  }
  .news-content p {
    font-size: 1rem;
    font-weight: 500;
  }
}
