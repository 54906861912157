@import "./styles/StyleReset.scss";

@import "./styles/Scrollbar.scss";
// Events
// @import "./styles/Events.scss";

:root {
  font-size: 15px;
  --paralaxText: hsl(0, 0%, 100%);
  --grey0: hsl(40, 5%, 77%);
  --grey1: hsl(40, 5%, 50%);
  --grey2: hsl(40, 5%, 25%);
  --grey3: hsl(40, 5%, 10%);
  --grey4: hsl(0, 0%, 39%);
  --grey5: hsl(0, 0%, 26%);
  --grey5andAHalf: hsl(0, 0%, 19%);
  --basicallyBlack: hsl(0, 0%, 11%);
  --grey6: black;
}

.dark {
  --bodybg: var(--grey3);
  --headingtxt: var(--grey0);
  --bodytxt: var(--grey1);
  --activeNav: var(--grey0);
  --subtext: var(--grey6);
  --text: var(--grey0);
  --navbg: var(--grey5);
  --navtext: var(--grey3);
  --navLink: var(--grey1);
  --editbutton: var(--grey2);
}

body {
  font-family: "Poppins", sans-serif;
  height: 120%;
  background-color: #051308ec;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  z-index: -3;
}

#coverImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-image: url("./imgs/colby-mg-dark-md.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#coverPattern {
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 0;
  z-index: -0;
  background-image: url("./imgs/gr-bg-hero-pattern-dark.png");
  background-position: center;
  background-size: contain;
  background-repeat: repeat-y;
}

.gr-bg {
  background-blend-mode: darken;

  height: 1fr;
  background-position: top;
  background-repeat: repeat-y;
  background-size: contain;

  -webkit-filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
  filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  color: #fff;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 2rem;
  font-weight: 350;

  padding: 2rem 0 1rem 0;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.5rem;
  font-weight: 350;
}

h5 {
  font-size: 1rem;
  font-weight: 300;
}

p {
  font-size: 1rem;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nullspacingTop {
  margin-top: 0;
  padding-top: 0;
  transition: 0.5s ease;
}

.setBehind {
  z-index: -10;
}

.row {
  display: inline-flex;
  width: 100%;
  max-width: 100vw;
  transition: 0.5s ease;
  justify-content: center;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 1 / 3 / 2 / 4;
}

.fadeIn {
  animation: fadeIn 0.5s ease;
}

.fadeOut {
  animation: fadeOut 0.5s ease;
}

.scrollDown {
  animation: scrollDown 0.5s ease;
}

.scrollUp {
  animation: scrollUp 0.5s ease;
}

.container {
  padding: 1rem 1rem;
  position: relative;
}

.is-hidden {
  transition: 0.5s ease;
  opacity: 0;
}

@keyframes scrollDown {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes scrollUp {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spacer {
  width: 100%;
  height: 5rem;
  padding: 0;
  margin: 0;
  background-color: unset;
}

/* ! Toggle switch */

// /* The switch - the box around the slider */
// .switch {
//   position: relative;
//   display: inline-block;
//   width: 60px;
//   min-width: 60px;
//   height: 34px;
//   /* margin-left: 10%; */
// }

// /* Hide default HTML checkbox */
// .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
//   margin: auto;
// }

// /* The slider */
// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgb(206, 73, 73);
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
//   height: 34px;

//   &::before {
//     position: absolute;
//     content: "";
//     height: 26px;
//     width: 26px;
//     left: 4px;
//     bottom: 4px;
//     background-color: white;
//     -webkit-transition: 0.4s;
//     transition: 0.4s;
//   }
// }

// input:checked + .slider {
//   background-color: #2196f3;
// }

// input:focus + .slider {
//   box-shadow: 0 0 1px #2196f3;
// }

// input:checked + .slider:before {
//   -webkit-transform: translateX(26px);
//   -ms-transform: translateX(26px);
//   transform: translateX(26px);
// }

// /* Rounded sliders */
// .slider.round {
//   border-radius: 34px;
//   margin: 0;

//   &::before {
//     border-radius: 50%;
//   }
// }
