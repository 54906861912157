.BotNavItems {
  font-size: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  grid-auto-rows: max-content;
  margin: 0;
  padding: 0;
  transition: 0.5s ease;
  animation: fadeIn 0.5s ease;
}

footer {
  z-index: 9000;
}

footer .container {
  margin: 10rem 0 0 0;
  background-color: #051308af;

  // backdrop-filter: blur(10px);
}

.fLogo {
  position: absolute;
  bottom: 5rem;
  left: 5rem;
}

@media only screen and (max-width: 1089px) {
  .fLogo {
    bottom: 10rem;
    left: 4rem;
  }
}

.footer-logo {
  max-width: 6rem;
  align-self: center;
  margin: auto;
}

.Footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  justify-content: center;
  margin: 0 auto 0 15%;
  padding: 2rem 0;
  align-self: center;
  align-items: baseline;
  width: 85%;
}

@media only screen and (max-width: 640px) {
  .Footer {
    margin: 0 auto 0 auto;
    width: 100%;
  }
}

.FooterContainer {
  display: grid;
  gap: 0.5rem;
  grid-column: span 1;
  padding: 1rem 0;
  grid-auto-rows: max-content;
  // margin: 2rem 0 auto 0;
  // padding-bottom: auto;
  text-align: center;
}

.companyTag {
  & h3 {
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    font-weight: 300;
    margin-bottom: 2rem;
    // padding: 10rem, 0;
    // vertical-align: middle;
    color: hsl(0, 0%, 69%);
    &:hover {
      cursor: pointer;
      color: rgb(218, 255, 193);
    }
  }
}

#contactsect {
  transition: 0.5s ease;
}

#contactDiv {
  transition: 0.5s ease;
}

.socialMediaButton {
  width: 1.5rem;
  filter: invert(100%);
  padding: 0 1rem;
}
