.trans--grow {
  width: 0%;
}

.grow {
  transition: 1.5s ease-in-out;
  width: 100%;
}

.hr1 {
  margin-left: 0;
  z-index: 99;
}

hr {
  margin-top: 20px;
  padding: 2px 0;
  border: none;
  background-color: #97d700;
  letter-spacing: 5px;
}

.hr {
  display: block;
  margin: 0 auto;

  width: 50%;
}

.hr2 {
  display: block;
  margin: 0 auto;

  width: 70%;
}
