.initDiv {
  display: grid;
  grid-template-columns: repeat(2, 5rem);
  grid-template-rows: repeat(2, 5rem);
  gap: 1rem;
  justify-content: center;
  // align-items: center;
  vertical-align: middle;
}

.bigLogo {
  transition: 0.3s ease;
  align-self: center;
  vertical-align: middle;
  // height: 50%;
  // margin: auto;
}

.containingDiv {
  background-color: #051308;
  transition: 0.5s ease;
  position: absolute;
  z-index: 99999999;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.scrollLock {
  transition: 0.5s ease;
  /* width: 100%; */
  position: fixed;
  top: 0rem;
  left: 0rem;
}
