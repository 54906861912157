/* ! /////////////// hero ///////////////  */

.hero {
  // padding-top: 5vh;
  display: grid;
  grid-template-columns: 10vw 80vw 10vw;
  grid-template-rows: 100vh;
  grid-column-gap: 0;
  grid-row-gap: 0;
  font-size: 1.1rem;
  font-weight: 400;

  -webkit-filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308);
  filter: drop-shadow(0.3125rem 0.3125rem 0.5rem #051308ec);
  letter-spacing: 0.05rem;
  line-height: 1.2rem;

  scroll-behavior: smooth;
  & p {
    margin-top: 0.9rem;
  }

  & h1 {
    margin-top: 3vh;
  }
}

@media only screen and (max-height: 600px) and (max-device-width: 600px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .hero {
    display: grid;
    grid-template-rows: 180vh;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
}

.hero-content {
  color: #fff;

  //   min-height: 1000px;

  margin: 15% 1% 0 1%;
  text-align: center;
  text-shadow: 0.625rem 0.3125rem 0.625rem #051308;
}

.company-mission {
  min-width: 15rem;
  max-width: 40rem;
  margin: auto;
  text-align: center;
}

.hero-content h1 {
  padding-top: 2rem;
}

.hero-content h2 {
  padding-top: 2rem;
}

.hero-content h4 {
  padding-top: 2rem;
}

.hero-logo {
  width: 90%;
  max-width: 31.25rem;

  padding: 2rem 0 0 0;

  -webkit-filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
  filter: drop-shadow(0.3125rem 0.3125rem 0.3125rem #051308ec);
}

@media screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .hero-logo {
    max-width: 20rem;
  }
}

@media screen and (max-width: 950px) {
  .hero-content h4 {
    font-size: 1rem;
    font-weight: 400;
  }
  .hero-logo {
    padding-top: 4rem;
  }
}

#contact-btn {
  background-color: #05130863;
  border: solid 0.125rem #78e73e;
  border-radius: 2.1875rem;
  color: #78e73e;
  font-family: "Poppins", sans-serif;

  margin-top: 3rem;
  margin-bottom: 1rem;

  padding: 0.9375rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;

  -webkit-filter: drop-shadow(0 0 0.3125rem #051308ec);
  filter: drop-shadow(0 0 0.3125rem #051308ec);
}

#contact-btn:hover {
  background-color: #051308a2;
  border: solid 0.125rem #fba338;

  color: #fba338;
  font-weight: 400;
  text-shadow: 0 0 0.3125rem #051308ec;
  cursor: pointer;
}
