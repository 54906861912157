/* Style the buttons that are used to open and close the accordion panel */
.FAQcontainer {
  padding-top: 1rem;
  min-height: 60vh;
}

.accordion {
  color: #fff;
  background-color: #051308af;
  cursor: pointer;
  padding: 1.5rem;
  border: 1px solid black;
  outline: none;
  transition: 0.5s;
  margin: 0;
  align-items: center;
  justify-self: center;
  width: 70vw;
  text-align: center;
  align-self: center;

  &.button {
    font: inherit;
    font-size: 1.5rem;
  }

  &.accoTop {
    border-radius: 5px 5px 0px 0px;
    margin-top: 2rem;
  }

  &.accoBottom {
    border-radius: 0px 0px 5px 5px;
  }
  &:hover {
    color: var(--grey3);
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  justify-content: center;
  transition: 0.5s ease;
  color: #00ff37af;
  background-color: #051308;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  transition: 0.5s ease;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  background-color: #051308;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
  width: 70vw;
  margin: auto;
  justify-content: center;
  align-items: center;

  font-size: 1.1rem;
  font-weight: 400;

  letter-spacing: 0.05rem;
  line-height: 1.2rem;

  & a {
    color: white;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: #00ff37af;
    }
  }

  & p {
    padding: 1.5rem 5%;
  }
}

.accordionContainer {
  transition: 0.5s ease;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.accordionItem {
  width: 100%;
}

@media only screen and (max-width: 900px) {
}
