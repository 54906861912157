nav {
  transition: background 0.4s ease-in-out;
  max-width: 100vw;
}

a {
  text-decoration: none;
  // color: var(--navLink);
  text-decoration: none;

  &:visited {
    text-decoration: none;
    color: #fff;
    text-decoration: none;
  }

  &:visited.is-active {
    text-decoration: none;
    color: hsl(119, 60%, 51%);
  }
}

.TopNavBar {
  /* box-shadow: 0px 5px 10px grey; */
  position: fixed;
  left: 0;
  display: inline-flex;
  margin: 0;
  padding: 0;
  background-color: #051308ec;
  vertical-align: middle;
  width: 100%;
  transition: 0.5s ease;
}

@media only screen and (max-width: 900px) {
  /* For mobile phones: */
  .TopNavBar {
    height: 5rem;
  }
}

.TopNavItems {
  margin: auto 8% auto 0;
  transition: 0.5s ease;
  animation: fadeIn 0.5s ease;
}

.TopNavToggledItems {
  display: grid;
  justify-content: center;
  gap: 1rem;
  background-color: #051308ec;
  position: fixed;
  width: 80%;
  top: 6rem;
  left: 10%;
  padding: 0;
  margin: auto auto auto 0;
  display: flexbox;

  flex-direction: column;
  transition: 0.5s ease;
  animation: fadeIn 0.5s ease;
  -webkit-box-shadow: 0 0 0.625rem white;
  box-shadow: 0 0 0.625rem #051308ec;
  // background-image: url("../../imgs/gr-toggle-bg.png");
  background-repeat: repeat-y;
  background-size: 6rem;
  background-position: 1rem;
}

.TopNavToggledLogo {
  position: absolute;
  transition: 0.5s;
  width: 6rem;
  left: 1rem;
  top: 0.25rem;
}

.TopNavCollapse {
  // background-color: black;
  // animation: scrollDown 0.5s ease;
  // margin: 0 auto 0 auto;
  // position: absolute;

  // padding-top: 5rem;
  position: fixed;
  top: 6rem;
  // transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

  left: 0;
  right: 0;
}

.TopNavItem {
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
  margin: 0rem 1rem;
  color: #fff;
  transition: 0.5s ease;

  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: #50b44e;
    cursor: pointer;
    transition: 0.5s ease;
  }
}

.TopNavToggledItem {
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
  margin: 0rem 1rem;
  color: #fff;
  transition: 0.5s ease;

  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: #50b44e;
    cursor: pointer;
    transition: 0.5s ease;
  }
  &:last-child {
    padding: 0 0 1rem 0;
  }
  &:first-child {
    padding: 1rem 0 0 0;
  }
}

@media only screen and (max-width: 400px) {
  .TopNavToggledItem {
    padding-left: 5rem;
    &:last-child {
      padding: 0 0 1rem 5rem;
    }
    &:first-child {
      padding: 1rem 0 0 5rem;
    }
  }
}

.TopNavLogo {
  padding: 1rem 0;
  margin: auto auto auto 8%;
  transition: 0.5s ease;
}

.Logo {
  max-width: 15rem;
  margin: auto;
  transition: 0.5s ease;
  &:hover {
    cursor: pointer;
  }
}

.is-active {
  text-decoration: none;
  color: hsl(119, 60%, 51%);
}

/* ! NavTrigger */
.navTrigger {
  padding: 0.75rem 0 0 0;
  /* background-color: aqua; */
  position: absolute;

  border-radius: 10%;
  cursor: pointer;
  width: 3rem;
  height: 2.5rem;
  margin: auto;
  right: 3rem;
  top: 1rem;
  transition: 0.5s ease;
  /* bottom: 0; */

  &:active {
    background-color: rgba(0, 0, 0, 0);
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 400px) {
  .navTrigger {
    right: 1.5rem;
  }
  .TopNavLogo {
    margin: auto auto auto 5%;
  }
}

.noTouch {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.navTrigger i:hover {
  cursor: pointer;
}

.navTrigger i {
  background-color: #fff;

  border-radius: 2px;
  display: block;
  width: 100%;
  height: 3px;
}

.navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(8px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(8px) rotate(0deg);
  }
  100% {
    transform: translateY(8px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(8px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(8px) rotate(0deg);
  }
  100% {
    transform: translateY(8px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-8px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-8px) rotate(0deg);
  }
  100% {
    transform: translateY(-8px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-8px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-8px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-8px) rotate(0deg);
  }
  100% {
    transform: translateY(-8px) rotate(135deg);
  }
}
